/************
 * Developed by : Shiva Software Solutions
 * Date : 21-10-2022
 * Descriptions : Route page for erx upload page
 * Dependicies : To use @material-ui/core,react-router-dom,formik
 ************/
import React from 'react';
import {authRole} from '../../shared/constants/AppConst';

export const gangaramReport = [
  {
    auth: authRole.admins,
    routes: [
      {
        path: '/registrationReport',
        component: React.lazy(() => import('./gangaramReport')),
      },
    ],
  },
];
